.action {
  display: block;

  .trigger {
    background: none;
    border: none;
    padding: 0;

    &.disabled {
      pointer-events: none;
    }
  }
}

.modal {
  padding: 30px;
  text-align: center;
  background: #242526;
  border: 1px solid #49494d;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 12px;

  .title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    color: #ffffff;
  }

  .description {
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    margin: 0 auto;
    color: #9ba5b4;
    max-width: 300px;
  }

  .render {
    margin-top: 30px;

    .buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;

      .button {
        width: 100%;
        background: #303234;
        border-radius: 7px;
        color: white;
        margin-left: 10px;
        padding: 10px;
        border: none;

        &.blue {
          background: #2044f5;
          margin-left: 10px;
        }
      }
    }
  }
}
